import React from 'react'
/* import { Link } from 'gatsby' */
/* import { graphql } from 'gatsby' */

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import styles from "./business.module.css"

const BusinessPage = () => (
	<Layout>
	<SEO title="事業内容" />
		<div className={styles.container}>
			<h1>事業内容</h1>
			<p>
			個人や法人などを問わず、対応が可能です。
			</p>

			<h2>ホスティングサービス</h2>
			<p>
			ホームページの開設・運用管理、SSL (HTTPS) 対応、メールサービスの管理、独自ドメインの取得・管理など
			</p>

			<h2>各種ネットワークの構築</h2>
			<p>
			ご自宅、事務所、イベント会場等でのインターネット接続環境の立ち上げ・管理。
			利用者の区分毎にアクセス可能な範囲を限定したネットワークの構築、地理的に離れた地点同士でのVPN接続など
			</p>

			<h2>無線LAN (Wi-Fi) 環境の構築・改善</h2>
			<p>
			ご自宅、事務所、イベント会場等での無線LAN (Wi-Fi) 環境の立ち上げ、「遅い」「繋がらない」「切れる」等の品質改善
			</p>

			<h2>PC・スマートフォン等のサポート、コンサルティング業務</h2>
			<p>
			PCやスマートフォンのトラブル、買い替えや乗り換えのご相談など
			</p>
		</div>
	</Layout>
)

export default BusinessPage

/* [EOF] */
